import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { ScenarioData } from '../../../shared/models/scenario.model';

@Component({
  selector: 'sc-scenario-card',
  templateUrl: 'scenario-card.component.html',
  styleUrls: ['scenario-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScenarioCardComponent implements OnChanges {
  @Input()
  bestCase = false;
  @Input()
  scenarioData: ScenarioData;
  @Input()
  isDashboardView = false;

  sector = 'Default';

  ngOnChanges(): void {
    if (this.scenarioData) {
      this.sector = this.scenarioData.sector;
    }
  }
}
