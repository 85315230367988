import { AccredibleDividerModule } from '@accredible-frontend-v2/components/divider';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { TranslocoModule } from '@jsverse/transloco';
import { ValueLabelComponentModule } from '../value-label/value-label.component.module';
import { ScenarioCardComponent } from './scenario-card.component';

const MATERIAL_MODULES = [MatTooltipModule];

@NgModule({
  declarations: [ScenarioCardComponent],
  imports: [
    CommonModule,
    TranslocoModule,
    ValueLabelComponentModule,
    AccredibleDividerModule,
    ...MATERIAL_MODULES,
  ],
  providers: [],
  exports: [ScenarioCardComponent],
})
export class ScenarioCardComponentModule {}
