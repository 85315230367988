/**
 * Every time some logic is changed here, make sure to make the same changes in
 * BaseFormInterface and AccredibleBaseFormDialogComponent if needed.
 */

import { ElementRef, Injectable, OnDestroy } from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { dirtyCheck } from '@ngneat/dirty-check-forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable } from 'rxjs';
import { BaseFormHelper } from './base-form.helper';
import { BaseFormInterface } from './base-form.interface';

@UntilDestroy()
@Injectable()
export class AccredibleBaseFormComponent<T> implements BaseFormInterface<T>, OnDestroy {
  formGroup: UntypedFormGroup;
  formGroupBehaviorSubject$: BehaviorSubject<T>;
  formGroupObservable$: Observable<T>;
  isDirty$: Observable<boolean>;

  private _isDirtySetup = false;

  constructor(protected _el: ElementRef<HTMLElement>) {}

  ngOnDestroy(): void {
    if (this._isDirtySetup) {
      this._resetIsDirty();
    }
  }

  getFormGroup(formGroup: UntypedFormGroup, key1: string, key2?: string): UntypedFormGroup {
    return BaseFormHelper.getFormGroup(formGroup, key1, key2);
  }

  getFormArray(formGroup: UntypedFormGroup, key1: string, key2?: string): UntypedFormArray {
    return BaseFormHelper.getFormArray(formGroup, key1, key2);
  }

  getFormArrayControl(formArrayControl: AbstractControl): UntypedFormControl {
    return BaseFormHelper.getFormArrayControl(formArrayControl);
  }

  getFormControl(formGroup: UntypedFormGroup, key1: string, key2?: string): UntypedFormControl {
    return BaseFormHelper.getFormControl(formGroup, key1, key2);
  }

  /**
   * Call this method on ngOnInit if you need your component to have dirty validation setup
   *
   * Dirty Validation will prevent the user from closing the form,
   * (when trying to change route or trying to close the website browser tab)
   * without saving changes
   */
  setupFormDirtyValidation(): void {
    this._isDirtySetup = true;
    this.formGroupBehaviorSubject$ = new BehaviorSubject(this.formGroup.getRawValue());
    this.formGroupObservable$ = this.formGroupBehaviorSubject$.asObservable();
    this.isDirty$ = dirtyCheck(this.formGroup, this.formGroupObservable$);

    this.formGroupObservable$.pipe(untilDestroyed(this)).subscribe((state) => {
      return this.formGroup.patchValue(state, { emitEvent: false });
    });
  }

  _isFormValid(): boolean {
    if (this.formGroup.valid) {
      if (this._isDirtySetup) {
        this._resetIsDirty();
      }

      return true;
    }

    // Trigger form validations
    this.formGroup.markAllAsTouched();

    this._focusFirstInvalidInput();

    return false;
  }

  /**
   * This method can be used after API calls to focus the first input with an error
   */
  _focusFirstInvalidInput(): void {
    // requestAnimationFrame in order to ensure the error class and text are already set in the inputs
    requestAnimationFrame(() => {
      (<HTMLElement>this._el.nativeElement.querySelector('input.ng-invalid'))?.focus();
    });
  }

  /**
   * This resets the isDirty value on the @ngneat/dirty-check-forms library,
   * avoiding an alert of showing up that prevents the url changing/refreshing,
   * when the form was actually submitted successfully
   * or when the user decided to leave the page without saving
   */
  _resetIsDirty(): void {
    this.formGroupBehaviorSubject$.next(this.formGroup.getRawValue());
  }
}
