import { AccredibleButtonLoadingComponentModule } from '@accredible-frontend-v2/components/button-loading';
import { AccredibleSelectInputComponentModule } from '@accredible-frontend-v2/forms/select-input';
import { AccredibleTextInputComponentModule } from '@accredible-frontend-v2/forms/text-input';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { TranslocoModule } from '@jsverse/transloco';
import { InputCardComponent } from './input-card.component';

const MATERIAL_MODULES = [MatButtonModule, MatIconModule, MatTooltipModule];

@NgModule({
  declarations: [InputCardComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    AccredibleTextInputComponentModule,
    AccredibleSelectInputComponentModule,
    AccredibleButtonLoadingComponentModule,
    TranslocoModule,
    ...MATERIAL_MODULES,
  ],
  providers: [],
  exports: [InputCardComponent],
})
export class InputCardComponentModule {}
