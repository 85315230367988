import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'accredible-divider',
  templateUrl: './divider.component.html',
  styleUrls: ['./divider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccredibleDividerComponent {
  @Input()
  vertical = false;
}
