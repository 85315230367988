<div class="card"
  *transloco="let t; read: 'input-card'"
  [class.dashboard-view]="!!dashboardData">
  <div class="content-padded">
    <form [formGroup]="formGroup"
      (ngSubmit)="onSubmitSalesData()">
      <div class="col-1">
        <div class="form-row">
          <accredible-text-input [formControlRef]="getFormControl(formGroup, 'numberOfUsers')"
            [label]="!!dashboardData ? t('number_of_credential_holders.label_embedded') : t('number_of_credential_holders.label')"
            [type]="'number'"
            [maxLength]="20">
            <span *ngIf="getFormControl(formGroup, 'numberOfUsers').hasError('required')"
              [innerHTML]="t('number_of_credential_holders.errors.required')">
            </span>
            <span *ngIf="getFormControl(formGroup, 'numberOfUsers').hasError('min')"
              [innerHTML]="t('number_of_credential_holders.errors.min')">
            </span>
            <span *ngIf="getFormControl(formGroup, 'numberOfUsers').hasError('maxlength')"
              [innerHTML]="t('number_of_credential_holders.errors.max_length')">
            </span>
          </accredible-text-input>
          <mat-icon class="mat-icon-rtl-mirror"
            [matTooltip]="t('number_of_credential_holders.tooltip')">help
          </mat-icon>
        </div>

        <div class="form-row">
          <!-- This will be a selector with values from cpcRatesData -->
          <accredible-select-input [formControlRef]="getFormControl(formGroup, 'credentialType')"
            [label]="t('credential_type.label')"
            [options]="credentialTypes">
            <span *ngIf="getFormControl(formGroup, 'credentialType').hasError('required')"
              [innerHTML]="t('credential_type.errors.required')">
            </span>
          </accredible-select-input>
          <mat-icon class="mat-icon-rtl-mirror"
            [matTooltip]="t('credential_type.tooltip')">help
          </mat-icon>
        </div>

        <div class="form-row">
          <!-- This will be a selector with values from availableSectors -->
          <accredible-select-input [formControlRef]="getFormControl(formGroup, 'sector')"
            [label]="t('sector.label')"
            [options]="cpcRatesData">
            <span *ngIf="getFormControl(formGroup, 'sector').hasError('required')"
              [innerHTML]="t('sector.errors.required')">
            </span>
          </accredible-select-input>
          <mat-icon class="mat-icon-rtl-mirror"
            [matTooltip]="t('sector.tooltip')">help
          </mat-icon>
        </div>
      </div>

      <div class="col-2">
        <div>
          <div class="form-row">
            <accredible-text-input [formControlRef]="getFormControl(formGroup, 'offeringValue')"
              [label]="t('offering_value.label')"
              [type]="'number'">
              <span *ngIf="getFormControl(formGroup, 'offeringValue').hasError('required')"
                [innerHTML]="t('offering_value.errors.required')">
              </span>
              <span *ngIf="getFormControl(formGroup, 'offeringValue').hasError('min')"
                [innerHTML]="t('offering_value.errors.min')">
              </span>
              <span *ngIf="getFormControl(formGroup, 'offeringValue').hasError('maxlength')"
                [innerHTML]="t('offering_value.errors.max_length')">
              </span>
            </accredible-text-input>
            <mat-icon class="mat-icon-rtl-mirror"
              [matTooltip]="t('offering_value.tooltip')">help
            </mat-icon>
          </div>

          <div class="form-row">
            <accredible-text-input [formControlRef]="getFormControl(formGroup, 'closeRate')"
              [label]="t('close_rate.label')"
              [type]="'number'">
              <span *ngIf="getFormControl(formGroup, 'closeRate').hasError('required')"
                [innerHTML]="t('close_rate.errors.required')">
              </span>
              <span *ngIf="getFormControl(formGroup, 'closeRate').hasError('min')"
                [innerHTML]="t('close_rate.errors.min')">
              </span>
              <span *ngIf="getFormControl(formGroup, 'closeRate').hasError('maxlength')"
                [innerHTML]="t('close_rate.errors.max_length')">
              </span>
              <span *ngIf="getFormControl(formGroup, 'closeRate').hasError('max')"
                [innerHTML]="t('close_rate.errors.max')">
              </span>
            </accredible-text-input>
            <mat-icon class="mat-icon-rtl-mirror"
              [matTooltip]="t('close_rate.tooltip')">help
            </mat-icon>
          </div>
        </div>

        <div class="button-wrapper">
          <button mat-flat-button
            color="primary"
            type="submit">{{ t('calculate_button') }}
          </button>
        </div>

      </div>

    </form>
  </div>
</div>
