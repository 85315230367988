<div class="card"
  *transloco="let t; read: 'scenario-card'"
  [class.dashboard-view]="isDashboardView"
  [class.gutter-margin]="isDashboardView && bestCase">
  <div class="content-padded">
    <h2 *ngIf="isDashboardView; else standaloneHeading">{{ bestCase ? t('best_case_dashboard') : t('average_case_dashboard') }}</h2>
    <ng-template #standaloneHeading>
      <h2>{{ (bestCase ? t('best_case') : t('average_case')) }}</h2>
    </ng-template>

    <p *ngIf="isDashboardView; else standaloneSummary"
      class="summary"
      [innerHTML]="bestCase ? t('best_case_dashboard_summary') : t('average_case_dashboard_summary')"></p>
    <ng-template #standaloneSummary>
      <p class="summary"
        [innerHTML]="bestCase ? t('best_case_summary', { sector: sector }) : t('average_case_summary', { sector: sector })"></p>
    </ng-template>

    <div class="card-row">
      <sc-value-label [value]="scenarioData?.engagementRate ? (scenarioData?.engagementRate * 100 | number: '1.0-1') : null"
        [label]="t('engagement_rate.label')"
        [type]="'percentage'"
        [matTooltip]="''">
      </sc-value-label>

      <sc-value-label [value]="scenarioData?.shareRate ? (scenarioData?.shareRate * 100 | number: '1.0-1') : null"
        [label]="t('share_rate.label')"
        [type]="'percentage'">
      </sc-value-label>
    </div>

    <accredible-divider></accredible-divider>

    <div class="card-row">
      <sc-value-label [value]="(scenarioData?.sharesGenerated | number: '1.0-0') || null"
        [label]="t('shares_generated.label')">
      </sc-value-label>

      <sc-value-label [value]="(scenarioData?.referralClicks | number: '1.0-0') || null"
        [label]="t('referral_clicks.label')">
      </sc-value-label>
    </div>
  </div>

  <div class="bottom"
    [style.background]="bestCase ? 'linear-gradient(60deg, #444798, #5254c5' : '#60618f'">
    <sc-value-label [value]="(scenarioData?.referralValue | number: '1.0-0') || null"
      [label]="t('referral_value.label')"
      [type]="'currency'">
    </sc-value-label>

    <sc-value-label [value]="(scenarioData?.revenueGenerated | number: '1.0-0') || null"
      [label]="t('sign_up_revenue.label')"
      [type]="'currency'">
    </sc-value-label>
  </div>
</div>
