import { NgModule } from '@angular/core';
import { ValueLabelComponent } from './value-label.component';

@NgModule({
  declarations: [ValueLabelComponent],
  imports: [],
  providers: [],
  exports: [ValueLabelComponent],
})
export class ValueLabelComponentModule {}
