import { NgModule } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { AccredibleDividerComponent } from './divider.component';

const MATERIAL_MODULES = [MatDividerModule];

@NgModule({
  imports: [...MATERIAL_MODULES],
  exports: [AccredibleDividerComponent],
  declarations: [AccredibleDividerComponent],
  providers: [],
})
export class AccredibleDividerModule {}
