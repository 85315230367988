<div class="accredible-input-error-container">

  <ng-container *transloco="let t; read: 'input-error'">

    <mat-icon class="trailing-margin-narrow icon-warning"
      aria-hidden="false"
      [attr.aria-label]="t('aria_error')">
    </mat-icon>

    <ng-content></ng-content>

  </ng-container>

</div>
