import { FocusMonitor } from '@angular/cdk/a11y';
import { ElementRef, Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class AccredibleKeyboardFocusService {
  constructor(private readonly _focusMonitor: FocusMonitor) {}

  /**
   * Tracks focus origin of the passed element.
   * In order to enable the outline only when keyboard focused we add the following to the components scss file element block:
   *
   * &.focus-visible:not(.cdk-keyboard-focus) { outline: none; }
   */
  startFocusMonitoring(el: ElementRef<HTMLElement>): void {
    this._focusMonitor.monitor(el).subscribe();
  }

  /**
   * Stops the focus origin tracking for the passed element.
   */
  stopFocusMonitoring(el: ElementRef<HTMLElement>): void {
    this._focusMonitor.stopMonitoring(el);
  }
}
