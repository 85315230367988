import { ButtonColor } from '@accredible-frontend-v2/new-components/button';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'accredible-button-loading, accredible-button-loading.fill-width',
  templateUrl: './button-loading.component.html',
  styleUrls: [`./button-loading.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonLoadingComponent {
  @Input()
  isLoading = false;
  @Input()
  btnType = 'button';
  @Input()
  btnColor: ButtonColor = 'primary';
  @Input()
  btnText: string;
  @Input()
  size: 'small' | 'normal' | 'large' = 'normal';
  @Input()
  disableBtn = false;
  @Input()
  dataCy: string;
}
