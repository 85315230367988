import { environment } from '@accredible-frontend-v2/envs';
import { Component, OnInit } from '@angular/core';
import cpcRatesData from '../../assets/data/cpc_rates.json';
import referenceRatesData from '../../assets/data/reference_rates.json';
import {
  DashboardSalesCalculatorData,
  RawCpcRatesSectorData,
  RawReferenceRatesData,
  ReferenceRatesSectorData,
  SelectInputOptionsData,
} from '../shared/models/data.model';
import { InputData } from '../shared/models/input.model';
import { ScenarioData } from '../shared/models/scenario.model';
import { extractCredentialTypes, mapCpcData, mapReferenceData } from './sales-calculator.helper';

@Component({
  selector: 'sc-root',
  templateUrl: './sales-calculator.container.html',
  styleUrls: ['./sales-calculator.container.scss'],
})
export class SalesCalculatorContainer implements OnInit {
  // Processed Rates Data from JSON files
  cpcRatesData: SelectInputOptionsData[];
  referenceRatesData: ReferenceRatesSectorData;
  credentialTypes: { name: string }[];

  // Data for the cards
  bestCaseData: ScenarioData;
  averageCaseData: ScenarioData;
  dashboardData: DashboardSalesCalculatorData | null;

  backgroundColor: string;
  readonly version = environment.version;

  // Raw rates data from JSON files
  private _initialCpcData: RawCpcRatesSectorData[] = cpcRatesData;
  private _initialReferenceData: RawReferenceRatesData[] = referenceRatesData;

  ngOnInit(): void {
    const url = new URL(document.location.href);
    // Background-color must be in hex format
    const backgroundColorParam = url.searchParams.get('background-color');
    this.backgroundColor = backgroundColorParam ? `#${backgroundColorParam}` : '#f4f5fa';

    this.dashboardData = JSON.parse(url.searchParams.get('data'));

    // Map the JSON data
    this.cpcRatesData = mapCpcData(this._initialCpcData);
    this.referenceRatesData = mapReferenceData(this._initialReferenceData);

    // Create the sectorNameArray
    this.credentialTypes = extractCredentialTypes(this.referenceRatesData);

    // If we have data from the dashboard we need to calculate the initial values excluding referral and revenue generated
    if (this.dashboardData) {
      const initialData = <InputData>{
        closeRate: 2,
        credentialType: 'Default',
        numberOfUsers: this.dashboardData.totalCredentials,
        sector: 2.69,
      };
      this.bestCaseData = this._generateScenarioData(
        initialData,
        'Best-Case',
        this.dashboardData,
        true,
      );
      this.averageCaseData = this._generateScenarioData(initialData, 'Average', null, true);
    }
  }

  onFormSubmitted(inputData: InputData): void {
    // Generate the best case and average case data for the scenario cards
    this.bestCaseData = this._generateScenarioData(inputData, 'Best-Case', this.dashboardData);
    this.averageCaseData = this._generateScenarioData(inputData, 'Average');
  }

  // Returns an object of type ScenarioData based on given inputData and scenario type
  private _generateScenarioData(
    inputData: InputData,
    scenarioType: 'Best-Case' | 'Average',
    dashboardData?: DashboardSalesCalculatorData | null,
    excludeReferralAndRevenue?: boolean,
  ): ScenarioData {
    const output = <ScenarioData>{};

    output.sector = inputData.credentialType;

    if (dashboardData) {
      output.engagementRate = dashboardData.engagementRate / 100;
      output.referralClicks = dashboardData.referralClicks;
      output.sharesGenerated = dashboardData.totalShares;
      output.shareRate = dashboardData.totalShares / dashboardData.totalCredentials;
    } else {
      // Comes as a decimal multiply by 100 to get percentage
      output.engagementRate = this._getSectorProperty(
        'engagementRate',
        scenarioType,
        inputData.credentialType,
      );
      // Comes as a decimal multiply by 100 to get percentage
      output.shareRate = this._getSectorProperty(
        'shareRate',
        scenarioType,
        inputData.credentialType,
      );
      output.sharesGenerated = output.shareRate * inputData.numberOfUsers;
      output.referralClicks =
        inputData.numberOfUsers *
        this._getSectorProperty('referralRate', scenarioType, inputData.credentialType);
    }

    // We only add referral and revenue data if it is required
    if (!excludeReferralAndRevenue) {
      output.referralValue = inputData.sector * output.referralClicks;
      output.revenueGenerated =
        output.referralClicks * (inputData.closeRate / 100) * inputData.offeringValue;
    }

    return output;
  }

  // Returns the value of a given property in a given sector from the referenceRatesData
  private _getSectorProperty(
    property: 'engagementRate' | 'referralRate' | 'shareRate',
    scenarioType: 'Best-Case' | 'Average',
    sector,
  ): number {
    // Best Case
    if (scenarioType === 'Best-Case') {
      return this.referenceRatesData[sector].best[property];
    } else {
      // Average Case
      return this.referenceRatesData[sector].average[property];
    }
  }
}
