import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { ButtonLoadingComponent } from './button-loading.component';

const MATERIAL_MODULES = [MatButtonModule, MatProgressSpinnerModule];

@NgModule({
  imports: [CommonModule, ...MATERIAL_MODULES],
  exports: [ButtonLoadingComponent],
  declarations: [ButtonLoadingComponent],
  providers: [],
})
export class AccredibleButtonLoadingComponentModule {}
