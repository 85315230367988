import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'sc-value-label',
  templateUrl: 'value-label.component.html',
  styleUrls: ['value-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValueLabelComponent implements OnChanges {
  @Input()
  value: string;
  @Input()
  label: string;
  @Input()
  type: 'percentage' | 'currency' | null;

  displayValue: string;

  static _buildValue(value: string, type: 'percentage' | 'currency' | null): string {
    if (type) {
      return type === 'percentage' ? `${value}%` : `$${value}`;
    } else {
      return value.toString();
    }
  }

  ngOnChanges(): void {
    this.displayValue = this.value ? ValueLabelComponent._buildValue(this.value, this.type) : '--';
  }
}
