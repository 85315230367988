import { AccredibleLanguageServiceModule } from '@accredible-frontend-v2/services/language';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InputCardComponentModule } from './components/input-card/input-card.component.module';
import { ScenarioCardComponentModule } from './components/scenario-card/scenario-card.component.module';
import { SalesCalculatorContainer } from './sales-calculator.container';

@NgModule({
  declarations: [SalesCalculatorContainer],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    InputCardComponentModule,
    ScenarioCardComponentModule,
    AccredibleLanguageServiceModule.forRoot(['en']),
  ],
  providers: [],
  bootstrap: [SalesCalculatorContainer],
})
export class SalesCalculatorContainerModule {}
