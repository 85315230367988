<mat-form-field [floatLabel]="'always'"
  [class.hide-label]="!label">

  <!-- NB: adding *ngIf to hide mat-label will lead to placeholders being promoted to a label when a label isn't present -->
  <mat-label>
    {{ label }}

    <mat-icon *ngIf="!!hint"
      class="hint-icon icon-info"
      [matTooltip]="hint">
    </mat-icon>
  </mat-label>

  <mat-icon *ngIf="!!icon && type !== 'textarea'"
    [ngClass]="icon"
    class="icon">
  </mat-icon>

  <mat-icon *ngIf="searchable && type !== 'textarea'"
    class="search-icon icon-search">
  </mat-icon>

  <textarea *ngIf="type === 'textarea'; else textInputTemplate"
    #textareaInput
    matInput
    [style.minHeight]="textAreaMinHeight + 'px'"
    [placeholder]="placeholder"
    [readOnly]="readonly"
    [formControl]="formControlRef"
    [cdkTextareaAutosize]="true"
    [required]="required"
    [attr.maxLength]="maxLength || null"
    [attr.aria-label]="ariaLabel"
    [attr.data-cy]="dataCy ? dataCy : null"
    (blur)="onBlur($event)">
  </textarea>

  <ng-template #textInputTemplate>
    <input #textInput
      matInput
      [class.has-icon]="!!icon"
      [class.searchable]="searchable"
      [placeholder]="placeholder"
      [readonly]="readonly"
      [type]="type"
      [autocomplete]="autoComplete"
      [formControl]="formControlRef"
      [required]="required"
      [attr.maxLength]="type !== 'number' ? maxLength || null : null"
      [attr.aria-label]="ariaLabel"
      [attr.data-cy]="dataCy ? dataCy : null"
      (blur)="onBlur($event)">
  </ng-template>

  <mat-hint *ngIf="legacyHint">{{ legacyHint }}</mat-hint>

  <mat-error *ngIf="!noError">
    <accredible-input-error>
      <ng-content></ng-content>
    </accredible-input-error>
  </mat-error>

</mat-form-field>
