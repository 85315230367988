<div class="version">{{ version }}</div>

<div class="app-wrapper"
  [style.backgroundColor]="backgroundColor"
  [class.dashboard-view]="!!dashboardData">

  <div class="content-wrapper"
    [class.dashboard-view]="!!dashboardData">
    <sc-input-card [cpcRatesData]="cpcRatesData"
      [credentialTypes]="credentialTypes"
      [dashboardData]="dashboardData"
      (formSubmitted)="onFormSubmitted($event)">
    </sc-input-card>

    <div class="scenario-wrapper">
      <sc-scenario-card [bestCase]="true"
        [scenarioData]="bestCaseData"
        [isDashboardView]="!!dashboardData">
      </sc-scenario-card>

      <sc-scenario-card [scenarioData]="averageCaseData"
        [isDashboardView]="!!dashboardData">
      </sc-scenario-card>
    </div>
  </div>
</div>
