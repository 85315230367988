import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';

export class BaseFormHelper {
  static getFormGroup(formGroup: UntypedFormGroup, key1: string, key2?: string): UntypedFormGroup {
    if (!!key2) {
      return <UntypedFormGroup>formGroup.get(key1).get(key2);
    }

    return <UntypedFormGroup>formGroup.get(key1);
  }

  static getFormArray(formGroup: UntypedFormGroup, key1: string, key2?: string): UntypedFormArray {
    if (!!key2) {
      return <UntypedFormArray>formGroup.get(key1).get(key2);
    }

    return <UntypedFormArray>formGroup.get(key1);
  }

  static getFormArrayControl(formArrayControl: AbstractControl): UntypedFormControl {
    return formArrayControl as UntypedFormControl;
  }

  static getFormControl(
    formGroup: UntypedFormGroup,
    key1: string,
    key2?: string,
  ): UntypedFormControl {
    if (!!key2) {
      return <UntypedFormControl>formGroup.get(key1).get(key2);
    }

    return <UntypedFormControl>formGroup.get(key1);
  }
}
