import {
  RawCpcRatesSectorData,
  RawReferenceRatesData,
  ReferenceRatesSectorData,
  SelectInputOptionsData,
} from '../shared/models/data.model';

//*** Data mapping methods ***//

// Map the json data into a easier format
export const mapCpcData = (cpcData: RawCpcRatesSectorData[]): SelectInputOptionsData[] => {
  return cpcData.reduce((outputData, sector) => {
    if (sector.Sector.includes('(default CPC')) {
      outputData.push({ name: 'Default', value: +sector['CPC value'].split('$')[1] });
    } else {
      outputData.push({ name: sector.Sector, value: +sector['CPC value'].split('$')[1] });
    }
    return outputData;
  }, []);
};

// Map the json data into a easier format
export const mapReferenceData = (
  referenceData: RawReferenceRatesData[],
): ReferenceRatesSectorData => {
  return referenceData.reduce((outputData, sector) => {
    const best = {};
    best['engagementRate'] = sector['Engagement Rate (BEST)'];
    best['referralRate'] = sector['Referral Rate (BEST)'];
    best['shareRate'] = sector['Share Rate (BEST)'];

    const average = {};
    average['engagementRate'] = sector['Engagement Rate (AVG)'];
    average['referralRate'] = sector['Referral Rate (AVG)'];
    average['shareRate'] = sector['Share Rate (AVG)'];

    if (sector.Sector.includes('(Default)')) {
      outputData['Default'] = { best, average };
    } else {
      outputData[sector.Sector] = { best, average };
    }
    return outputData;
  }, {});
};

// Create an array for the credential types select input options on the input card component
export const extractCredentialTypes = (
  referenceRatesData: ReferenceRatesSectorData,
): { name: string }[] => {
  return Object.keys(referenceRatesData).map((sector: string) => {
    return { name: sector };
  });
};
