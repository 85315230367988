<button mat-flat-button
  [attr.data-cy]="dataCy ? dataCy : null"
  [ngClass]="[btnColor, size]"
  [type]="btnType"
  [color]="btnColor"
  [disabled]="disableBtn">

  <div class="btn-content">
    <div [style.visibility]="isLoading ? 'hidden' : 'visible'">
      {{ btnText }}
    </div>

    <mat-spinner *ngIf="isLoading"
      diameter="25">
    </mat-spinner>
  </div>
</button>
