import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoModule } from '@jsverse/transloco';
import { InputErrorComponent } from './input-error.component';

const MATERIAL_MODULES = [MatIconModule];

@NgModule({
  imports: [TranslocoModule, ...MATERIAL_MODULES],
  exports: [InputErrorComponent],
  declarations: [InputErrorComponent],
  providers: [],
})
export class AccredibleInputErrorModule {}
