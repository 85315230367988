import { AccredibleBaseFormComponent } from '@accredible-frontend-v2/forms/utils';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import {
  DashboardSalesCalculatorData,
  SelectInputOptionsData,
} from '../../../shared/models/data.model';
import { InputData } from '../../../shared/models/input.model';

@Component({
  selector: 'sc-input-card',
  templateUrl: 'input-card.component.html',
  styleUrls: ['input-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputCardComponent extends AccredibleBaseFormComponent<{}> implements OnInit {
  @Input()
  cpcRatesData: SelectInputOptionsData[];
  @Input()
  credentialTypes: { name: string }[];
  @Input()
  dashboardData: DashboardSalesCalculatorData | null;

  @Output()
  formSubmitted = new EventEmitter<InputData>();

  constructor(protected _el: ElementRef<HTMLElement>, private _fb: UntypedFormBuilder) {
    super(_el);
  }

  ngOnInit(): void {
    if (this.dashboardData) {
      // If we are on the dashboard view we want to populate the form with the dashboardData
      this.formGroup = this._fb.group({
        numberOfUsers: [
          {
            value: this.dashboardData.totalCredentials,
            disabled: true,
          },
          [Validators.required, Validators.min(0), Validators.maxLength(15)],
        ],
        credentialType: ['Default', Validators.required],
        sector: ['2.69', Validators.required],
        offeringValue: ['', [Validators.required, Validators.min(0), Validators.maxLength(15)]],
        closeRate: [
          2,
          [Validators.required, Validators.min(0), Validators.maxLength(10), Validators.max(100)],
        ],
      });
    } else {
      this.formGroup = this._fb.group({
        numberOfUsers: ['', [Validators.required, Validators.min(0), Validators.maxLength(15)]],
        credentialType: ['', Validators.required],
        sector: ['', Validators.required],
        offeringValue: ['', [Validators.required, Validators.min(0), Validators.maxLength(15)]],
        closeRate: [
          2,
          [Validators.required, Validators.min(0), Validators.maxLength(10), Validators.max(100)],
        ],
      });
    }
    this.setupFormDirtyValidation();
  }

  onSubmitSalesData(): void {
    if (this._isFormValid()) {
      this.formSubmitted.emit(this.formGroup.getRawValue());
    }
  }
}
